import { atom } from "recoil";

import { setInitialDate } from "@utils";
import { Account } from "@types";

/**
 * Select contract
 */
export const claimDetailsState = atom({
    key: "claimDetailsState",
    default: {
        periodFrom: setInitialDate(),
        periodTo: setInitialDate("plus", { years: 1 }),
        contractPartner: {} as Account,
        file: undefined as any,
        claimCustomerReference: undefined,
        claimCustomerInfo: undefined,
        isUkTeam: undefined,
        claimDocumentDate: undefined,
        claimDueDate: undefined,
    },
});
