import { Contract, CreateAccountForm } from "@types";
import { isArrayWithContent, isObjectWithContent } from "@utils";

/**
 * Api mapper hook
 */
export const useApiMapper = () => {
    /**
     * Contract Api mapper
     */
    const editContractMapper = (contractType: string, data: any) => {
        if (contractType === "VOLUME") {
            const volumeData = {
                contractType,
                externalReference: data?.externalReference,
                responsible: data?.responsible,
                icdNo: data?.icdNo,
                icdUrl: data?.icdUrl,
                categories: data?.categories?.map(el => el.categoryId),
                additionalPartners: data?.additionalPartners?.map(el => el.id),
                products: data?.products?.map(el => {
                    return {
                        contractPrice: el.contractPrice ?? undefined,
                        discountPercentage: el.discountPercentage ?? undefined,
                        productId: el.productId,
                        priceCondition:
                            el?.priceCondition?.priceCondition ||
                            el?.priceCondition ||
                            undefined,
                        scales: isArrayWithContent(el.scales)
                            ? el.scales
                            : undefined,
                    };
                }),
            };

            return volumeData;
        }

        if (contractType === "PERFORMANCE") {
            const performanceData = {
                contractType,
                externalReference: data?.externalReference || "",
                icdNo: data?.icdNo || "",
                icdUrl: data?.icdUrl || "",
                additionalPartners: data?.additionalPartners?.map(el => el.id),
                responsible: data?.responsible,
                products: [
                    {
                        productId: data?.products[0]?.productId,
                        milestones: data?.milestones,
                        financialConditions: isObjectWithContent(
                            data?.financialConditions,
                        )
                            ? {
                                  rebateAtInfusion: {
                                      [data?.financialConditions[
                                          "rebateAtInfusion"
                                      ]?.selectedCondition]:
                                          data?.financialConditions[
                                              "rebateAtInfusion"
                                          ]?.amount,
                                  },
                              }
                            : undefined,
                    },
                ],
            };

            return performanceData;
        }
    };

    const duplicateContractMapper = (contract: Contract, data: any) => {
        return {
            contractType: "PERFORMANCE", // only performance based contracts can be duplicated,
            country: contract?.country?.code,
            startDate: contract?.startDate,
            endDate: contract?.endDate,
            brandId: contract?.brand?.id,
            reference: data?.reference,
            externalReference: data?.externalReference || "",
            primaryPartnerId: data?.primaryPartner?.accountId,
            icdNo: data?.icdNo || "",
            icdUrl: data?.icdUrl || "",
            responsible: data?.responsible || "",
            therapeuticAreaId: contract?.therapeuticArea?.id,
            indicationId: contract?.indication?.id,
            products: [
                {
                    productId: contract?.products[0]?.productId,
                    priceCondition: contract?.products[0]?.priceCondition,
                    currency: contract?.products[0]?.currency,
                    milestones: contract?.products[0]?.milestones?.map(ms => {
                        return {
                            description: ms?.description,
                            number: ms?.number,
                        };
                    }),
                    financialConditions:
                        contract?.products[0]?.financialConditions,
                },
            ],
            comments: contract?.comments,
        };
    };

    /**
     * Tasks Api mapper
     */

    const mapOutcomeApprovalTask = (
        contractRef: string,
        orderId: string,
        approved: string,
        rejectionReason: string,
        decisionInfo: string,
        overrideReason: string,
        newRebateAmount: number,
    ) => {
        const isOutcomeRejected = approved === "rejected";
        const isOutcomeApprovedWithDifferentAmount =
            approved === "approvedWithDifferentAmount";
        // WARNING: approved should be always true
        // In order to make the radio button work more clear
        // we use true or false only inside the component

        return {
            approved: isOutcomeRejected ? false : true,
            contractRef,
            orderId,
            rejectionReason: isOutcomeRejected ? rejectionReason : undefined,
            decisionInfo:
                isOutcomeRejected || isOutcomeApprovedWithDifferentAmount
                    ? decisionInfo
                    : undefined,
            overrideReason: isOutcomeApprovedWithDifferentAmount
                ? overrideReason
                : undefined,
            newRebateAmount: isOutcomeApprovedWithDifferentAmount
                ? newRebateAmount
                : undefined,
        };
    };

    const mapReviewTask = (
        contractRef: string,
        approved: string,
        reasonRejection: string,
    ) => {
        const isApproved = approved ? JSON.parse(approved) : null;

        return {
            contractRef: contractRef,
            approved: isApproved,
            reasonRejection: isApproved === false ? reasonRejection : undefined,
        };
    };

    const mapClaimApprovalTask = (
        approved: string,
        rejectionReason: string,
        overrideReason: string,
        newClaimAmount: number,
    ) => {
        const isClaimRejected = approved === "rejected";
        const isClaimApprovedWithDifferentAmount =
            approved === "approvedWithDifferentAmount";

        return {
            approved: isClaimRejected ? false : true,
            rejectionReason:
                isClaimRejected === true ? rejectionReason : undefined,
            overrideReason: isClaimApprovedWithDifferentAmount
                ? overrideReason
                : undefined,
            newClaimAmount: isClaimApprovedWithDifferentAmount
                ? newClaimAmount?.toString()
                : undefined,
        };
    };

    const mapClaimOverrideApprovalTask = (
        approved: string,
        rejectionReason: string,
    ) => {
        const isOverrideApproved = approved === "true";

        return {
            approved: isOverrideApproved ? true : false,
            rejectionReason: !isOverrideApproved ? rejectionReason : undefined,
        };
    };

    const mapCreatePartner = (values: CreateAccountForm) => {
        return {
            accountAddress: values?.accountAddress,
            accountCity: values?.accountCity,
            accountName: values?.accountName,
            accountPostalCode: values?.accountPostalCode,
            accountTypeId: values?.accountType?.accountTypeId,
            accountStatus: values?.accountStatus.value,
            countryIsoCode: values?.countryIsoCode.countryIsoCode,
            homeCountry: values?.homeCountry.countryIsoCode,
            knowyoursupplierid: values?.knowyoursupplierid,
            sapAccountCode: values?.sapAccountCode,
            bankType: values?.bankType ? values?.bankType.toString() : "",
            codsId: values?.codsId ? values?.codsId.toString() : "",
            trustAccountCode: values?.trustAccountCode?.accountId || undefined,
            cmuRegionCode: values?.cmuRegionCode?.regionId || undefined,
            regionCode: values?.regionCode?.regionId || undefined,
            regionCode1: values?.regionCode1?.regionId || undefined,
            regionCode2: values?.regionCode2?.regionId || undefined,
        };
    };

    return {
        editContractMapper,
        duplicateContractMapper,
        mapOutcomeApprovalTask,
        mapReviewTask,
        mapClaimApprovalTask,
        mapClaimOverrideApprovalTask,
        mapCreatePartner,
    };
};
