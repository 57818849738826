// lib
import { Box } from "@mui/material";
import React, { ReactNode, useEffect, useRef, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { Location } from "@reach/router";
import { StaticImage } from "gatsby-plugin-image";
import { useIdleTimer } from "react-idle-timer";
import { navigate } from "gatsby";

// own containers/components
import { Modal, Sidebar } from "@components";
import { Notifications } from "@containers";

//import footer img
/* import footer from "../../images/footer.svg";
import smartContractingLogo from "@images/smart-contracting.svg"; */

//recoil atoms
import { userState } from "@atoms";

// style
import * as style from "./style.module.scss";
import classNames from "classnames";
import { pathname } from "../../utils";

/**
 * Props type
 */
interface Props {
    children: ReactNode;
}

const Layout = ({ children }: Props) => {
    const ref = useRef<any>();
    // 300000 milliseconds = 5 minutes
    const IDLE_WARNING_TIMEOUT_OFFSET = 300000;
    // 900000 milliseconds = 15 minutes
    const IDLE_TIMEOUT = 900000;
    const [remainingTime, setRemainingTime] = useState(IDLE_TIMEOUT);
    const [showIdleWarning, setShowIdleWarning] = useState(false);

    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, [ref]);

    const { getRemainingTime, start } = useIdleTimer({
        onIdle: () => {
            setShowIdleWarning(false);
            navigate("/inactive");
        },
        onAction: () => {
            if (showIdleWarning) {
                setShowIdleWarning(false);
                setTimeout(() => {
                    setRemainingTime(IDLE_TIMEOUT);
                }, 1000);
            }
        },
        timeout: IDLE_TIMEOUT,
        crossTab: true,
        syncTimers: 500,
        eventsThrottle: 1000,
        events: ["mousedown", "touchstart", "MSPointerDown"],
        disabled:
            pathname?.includes("logout") || pathname?.includes("inactive"),
        stopOnIdle: true,
    });

    useEffect(() => {
        if (!pathname?.includes("logout") && !pathname?.includes("inactive")) {
            // eslint-disable-next-line smells/no-setinterval
            const interval = setInterval(() => {
                setRemainingTime(getRemainingTime());
                if (getRemainingTime() === 0) {
                    start();
                }
                setShowIdleWarning(
                    getRemainingTime() <= IDLE_WARNING_TIMEOUT_OFFSET,
                );
            }, 1000);

            return () => {
                clearInterval(interval);
            };
        }
    });

    const user = useRecoilValue<any>(userState);

    /**
     * Hide sidebar if the user is not loaded yet or the team is not selected if the role is user
     */

    const footerRenderer = useMemo(() => {
        return (
            <div className={style.footer}>
                <div className={style.wrapper}>
                    <StaticImage
                        src={"../../images/footer.svg"}
                        alt="footer"
                        placeholder="blurred"
                    />
                    {/*  <img src={footer} alt="footer" /> */}
                    <StaticImage
                        // no absolute path https://github.com/gatsbyjs/gatsby/discussions/35667
                        src={"../../images/smart-contracting.svg"}
                        alt="Smart contracting logo"
                        objectFit="contain"
                        placeholder="blurred"
                    />
                    {/*  <img
                        src={smartContractingLogo}
                        alt="Smart contracting logo"
                    /> */}
                </div>
            </div>
        );
    }, []);

    return (
        <div className={style.layout}>
            <Notifications />
            <Box className={style.body}>
                <Location>
                    {({ location }) =>
                        !!user && (
                            <div className={style.sidebar}>
                                <Sidebar
                                    id="layout"
                                    user={user}
                                    location={location}
                                />
                            </div>
                        )
                    }
                </Location>
                <Box
                    className={classNames(style.content, {
                        [style.fullWidth]: !user,
                    })}
                    tabIndex={0}
                    ref={ref}
                >
                    <Modal
                        open={showIdleWarning}
                        onClose={() => null}
                        id="inactive"
                        title="Session Timeout"
                        smallView
                    >
                        You will be logged out due to inactivity
                        <br />
                        in{" "}
                        {remainingTime < 60000
                            ? `${Math.floor(remainingTime / 1000)} seconds`
                            : `less than ${Math.ceil(
                                  Math.ceil(remainingTime / 60 / 1000),
                              )} minutes`}
                        .
                    </Modal>
                    {children}
                </Box>
                {footerRenderer}
            </Box>
        </div>
    );
};

export default React.memo(Layout);
