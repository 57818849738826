// Lib
import { Box, Typography, Grid } from "@mui/material";
import React, { Fragment, useState, useRef, useMemo } from "react";
import { navigate } from "gatsby";

// Own components
import {
    SearchBar,
    Table,
    FieldRenderer,
    CancelWithReasons,
    Dialog,
} from "@components";

// Constants
import { filterByCountry, HEADERS, ROWRENDERERCONST } from "@constants";

// Hooks
import {
    useGetClaim,
    useGetClaimById,
    useViewingOptions,
    usePermission,
    useSelectedCountry,
} from "@hooks";

// Types
import { SearchFilters, Permissions } from "@types";

// Utils
import {
    apiResponseCounter,
    constructQueryString,
    displayDate,
    isSuccessfulCall,
} from "@utils";

type ClaimItem = {
    claimHeaderId: string;
    filename: string;
    accountName: string;
    dateFrom: string;
    dateTo: string;
};

const SEARCH_BY = {
    GB: ["supplier", "claim reference number", "filename"],
    DE: ["supplier", "filename"],
    DEFAULT: ["supplier", "filename"],
};
const searchByRenderer = (
    countryIsoCode: string,
    isPriceCorrection: boolean,
) => {
    const arr = [...(SEARCH_BY[countryIsoCode] || SEARCH_BY.DEFAULT)];
    const prefix = "Search by";

    if (isPriceCorrection) {
        return `${prefix} supplier or filename...`;
    }

    if (arr.length === 1) {
        return `${prefix} ${arr[0]}...`;
    } else {
        const lastItem = arr.pop();
        return `${prefix} ${arr.join(", ")} or ${lastItem}...`;
    }
};

/**
 * ClaimsOverview
 */
const ClaimsOverview = () => {
    const [itemToCancel, setItemToCancel] = useState<ClaimItem | undefined>(
        undefined,
    );

    const [itemToDelete, setItemToDelete] = useState<ClaimItem>();
    const [itemToApprove, setItemToApprove] = useState<ClaimItem>();

    const isPriceCorrectionClaims = useMemo(() => {
        return location.pathname.includes("/price-correction-claims");
    }, [location]);

    /**
     * Search Ref
     */

    const searchRef = useRef<any>();

    /**
     * Hooks
     */
    const { viewingOptions, setViewingOptions } = useViewingOptions(
        isPriceCorrectionClaims
            ? ROWRENDERERCONST.PRICE_CORRECTION_CLAIMS
            : ROWRENDERERCONST.CLAIM,
    );

    //Permission
    const { hasPermissionToEditClaim }: Permissions = usePermission();

    /**
     * API
     */
    const {
        list: claimList,
        loading: { fetching: listLoading, canceling, deleting, approving },
        reload: getClaim,
        search,
        cancel: cancelClaim,
        delete: deleteClaim,
        approve: approveClaim,
    } = useGetClaim(isPriceCorrectionClaims);

    const { download: downloadClaimData }: any = useGetClaimById();

    const { countryIsoCode } = useSelectedCountry();

    const onSearch = (filters: SearchFilters) => {
        const hasFilters = Object.values(filters).some(filter => filter.length);

        const params = constructQueryString(filters);
        if (hasFilters) search(`${params}`);
        else getClaim();
    };

    /**
     * Cancel claim
     */
    const onClaimCancel = (reason: string) => {
        if (!reason || !itemToCancel?.claimHeaderId) return;
        cancelClaim(
            itemToCancel?.claimHeaderId,
            itemToCancel?.filename,
            reason,
        ).then(res => {
            if (!!res && isSuccessfulCall(res?.status)) {
                setItemToCancel(undefined);
                searchRef?.current?.onSearchReset();
                getClaim();
            }
        });
    };

    /**
     * Delete claim
     */
    const onClaimDelete = () => {
        if (!itemToDelete?.claimHeaderId) return;

        deleteClaim(itemToDelete.claimHeaderId, itemToDelete.filename).then(
            res => {
                if (!!res && isSuccessfulCall(res?.status)) {
                    setItemToDelete(undefined);
                    searchRef?.current?.onSearchReset();
                    getClaim();
                }
            },
        );
    };

    /**
     * Send claim for approval
     */
    const onClaimApprove = () => {
        if (!itemToApprove?.claimHeaderId) return;

        approveClaim(itemToApprove.claimHeaderId, itemToApprove.filename).then(
            res => {
                if (!!res && isSuccessfulCall(res?.status)) {
                    setItemToApprove(undefined);
                    searchRef?.current?.onSearchReset();
                    getClaim();
                }
            },
        );
    };

    /**
     * Render
     */
    return (
        <Fragment>
            <CancelWithReasons
                id={`cancel-claim`}
                title={`Cancel claim`}
                open={!!itemToCancel?.claimHeaderId}
                onClose={() => setItemToCancel(undefined)}
                onSubmit={onClaimCancel}
                loading={canceling}
            >
                <Grid container item xs={12} spacing={3} mb={5}>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`claim-details-file-name`}
                            label="File name"
                            value={itemToCancel?.filename}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`claim-details-account-name`}
                            label="Primary contract partner"
                            value={itemToCancel?.accountName}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`claim-details-date-from`}
                            label="Date from"
                            value={displayDate(itemToCancel?.dateFrom)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`claim-details-date-to`}
                            label="Date to"
                            value={displayDate(itemToCancel?.dateTo)}
                        />
                    </Grid>
                </Grid>
            </CancelWithReasons>

            <Dialog
                open={!!itemToDelete?.claimHeaderId}
                id={`dashboard-claims-delete-claim`}
                message={
                    <Typography
                        variant="subtitle2"
                        color="black"
                        component="span"
                    >
                        Are you sure you want to delete
                        <Typography
                            variant="body2"
                            component="span"
                        >{` (${itemToDelete?.filename})`}</Typography>
                        ? This action cannot be undone.
                    </Typography>
                }
                primaryButton={{
                    text: "Delete claim",
                    action: () => onClaimDelete(),
                    loading: deleting,
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: () => setItemToDelete(undefined),
                }}
            />

            <Dialog
                open={!!itemToApprove?.claimHeaderId}
                id={`dashboard-claims-approve-claim`}
                message={
                    <Typography
                        variant="subtitle2"
                        color="black"
                        component="span"
                    >
                        Are you sure you want to send
                        <Typography
                            variant="body2"
                            component="span"
                        >{` (${itemToApprove?.filename}) `}</Typography>
                        for approval?
                    </Typography>
                }
                primaryButton={{
                    text: "Send for approval",
                    action: () => onClaimApprove(),
                    loading: approving,
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: () => setItemToApprove(undefined),
                }}
            />

            <Box mt={2} mb={4} display="flex" justifyContent="flex-end">
                <SearchBar
                    id="dashboard-claims"
                    filterKey={
                        isPriceCorrectionClaims
                            ? ROWRENDERERCONST.PRICE_CORRECTION_CLAIMS
                            : ROWRENDERERCONST.CLAIM
                    }
                    handleSearch={(filters: SearchFilters) => onSearch(filters)}
                    placeholder={searchByRenderer(
                        countryIsoCode,
                        isPriceCorrectionClaims,
                    )}
                    viewingOptions={viewingOptions}
                    ref={searchRef}
                    startDate={false}
                    endDate={false}
                    setViewingOptions={setViewingOptions}
                    status={{
                        loading: false,
                        data: [
                            {
                                value: "NEW",
                                label: "New",
                            },
                            {
                                value: "DRAFT",
                                label: "Draft",
                            },
                            {
                                value: "OPEN",
                                label: "Open",
                            },
                            {
                                value: "CLOSED",
                                label: "Closed",
                            },
                            {
                                value: "CANCELLED_WITH_FINANCIAL_IMPACT",
                                label: "Cancelled with financial impact",
                            },
                            {
                                value: "CANCELLED_WITHOUT_FINANCIAL_IMPACT",
                                label: "Cancelled without financial impact",
                            },
                            {
                                value: "PENDING_CANCELLATION",
                                label: "Pending cancellation",
                            },
                        ],
                    }}
                />
            </Box>
            <Box mt={5}>
                <Box display="flex" alignItems="baseline" mb={4}>
                    <Typography variant="h2">
                        {isPriceCorrectionClaims
                            ? "Price correction claims"
                            : "Claims"}
                    </Typography>

                    <Typography ml={1} variant="caption1">
                        {apiResponseCounter(
                            claimList,
                            listLoading,
                            "claim|claims",
                        )}
                    </Typography>
                </Box>
                <Table
                    id="dashboard-claim-list"
                    headers={
                        isPriceCorrectionClaims
                            ? HEADERS.PRICE_CORRECTION_CLAIMS
                            : filterByCountry(HEADERS.CLAIM, countryIsoCode)
                    }
                    rows={claimList?.data?.records}
                    loading={listLoading}
                    type={
                        isPriceCorrectionClaims
                            ? ROWRENDERERCONST.PRICE_CORRECTION_CLAIMS
                            : ROWRENDERERCONST.CLAIM
                    }
                    viewingOptions={viewingOptions}
                    emptyMsg="No claims found!"
                    callbacks={{
                        onClaimDetailsClick: (claimHeaderId: string) =>
                            navigate(`/claims/claim-details/${claimHeaderId}/`),
                        cancelClaim: item => {
                            setItemToCancel(item);
                        },
                        downloadClaimValidationReport: (
                            claimHeaderId: string,
                            fileName: string,
                        ) =>
                            downloadClaimData(
                                claimHeaderId,
                                "CLAIM_VALIDATION",
                                fileName,
                            ),
                        onClaimApprove: item => setItemToApprove(item),
                        onClaimDelete: item => setItemToDelete(item),
                        onClaimEdit: ({ claimHeaderId }) =>
                            navigate(`/claims/edit-claim/${claimHeaderId}`),
                    }}
                    permissions={{ hasPermissionToEditClaim }}
                />
            </Box>
        </Fragment>
    );
};
export default ClaimsOverview;
