import { useLayoutEffect } from "react";
import { useAxios } from "./useAxios";
import type { Api } from "@types";

const BASE_URI = `/v0`;
/**
 *
 * Fetch partners
 * This endpoint used only to fetch all partners in Dashboard/partners
 */
export const usePartners = (path: string, type: string, getList = true) => {
    const url = `${BASE_URI}/${path}`;

    const {
        response: list,
        loading: fetching,
        fetch: loadList,
        error: listError,
    }: Api = useAxios(
        {
            method: "GET",
            url,
        },
        { errorHandler: `Failed to fetch ${type.split("|")[1]}` },
    );

    const {
        loading: creating,
        error: createError,
        fetch: create,
        response,
    }: Api = useAxios(
        {
            method: "POST",
            url,
        },
        {
            errorHandler: `Failed to create ${type.split("|")[0]}`,
        },
    );
    const {
        loading: updating,
        error: updateError,
        fetch: update,
    }: Api = useAxios(
        {
            method: "PUT",
            url,
        },
        {
            errorHandler: `Failed to update ${type.split("|")[0]}`,
        },
    );

    useLayoutEffect(() => {
        if (!getList) return;
        let unmounted = false;

        if (!unmounted) {
            loadList();
        }
        return () => {
            unmounted = true;
        };
    }, []);

    return {
        list: list,
        loading: { creating, fetching, updating },
        error: {
            list: listError,
            create: createError,
            update: updateError,
        },
        response,
        create: listItem => create({ data: listItem }),

        update: (path, savedItem) =>
            update(
                { url: BASE_URI + path, data: savedItem },
                {
                    successHandler: `${savedItem.accountName} was successfully updated`,
                },
            ),
        search: (searchParams: string) =>
            loadList({
                url: `${url}${searchParams}`,
            }),
        load: loadList,
    };
};
