// Libs
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";

// Own components
import { ActionMenu } from "@components";

// Utils
import { constantMapper, displayDate, camelCaseSplitter } from "@utils";

/**
 * Row type
 */
interface Row {
    name: ScrollSetting;
    contractRef: string;
    outcomeId: string;
    outcomeRef: string;
    salessystemOrderId: string;
    infusionDate: string;
    outcomeAction: string;
    milestoneNumber: string | number;
    outcomeDate: string;
    outcomeStatus: string;
    financialTriggerType: string;
}

/**
 * Props type
 */
interface Props {
    row: Row;
    callbacks?: any;
    rowIndex: number;
    id: string;
    permissions: any;
    isColVisible: (col: string) => boolean;
    isOutcomeFinancialConditions?: boolean;
}

const OutcomesOverviewRow = ({
    row,
    rowIndex,
    permissions,
    id,
    isColVisible,
    callbacks,
    isOutcomeFinancialConditions,
}: Props) => {
    return (
        <TableRow
            id={`${id}-row-${rowIndex}`}
            sx={{
                "&:last-child td, &:last-child th": {
                    border: 0,
                },
            }}
        >
            {isColVisible("contractRef") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.contractRef}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("salessystemOrderId") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.salessystemOrderId}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("infusionDate") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.infusionDate}
                    </Typography>
                </TableCell>
            )}
            {!isOutcomeFinancialConditions &&
                isColVisible("milestoneNumber") && (
                    <TableCell>
                        <Typography variant="caption2">
                            {row.milestoneNumber || "-"}
                        </Typography>
                    </TableCell>
                )}
            {!!isOutcomeFinancialConditions &&
                isColVisible("financialTriggerType") && (
                    <TableCell>
                        <Typography variant="caption2">
                            {row?.financialTriggerType &&
                                camelCaseSplitter(row?.financialTriggerType)}
                        </Typography>
                    </TableCell>
                )}

            {isColVisible("outcomeAction") && (
                <TableCell>
                    <Typography variant="caption2">
                        {constantMapper(row.outcomeAction) || "-"}
                    </Typography>
                </TableCell>
            )}
            {!isOutcomeFinancialConditions && isColVisible("outcomeDate") && (
                <TableCell>
                    <Typography variant="caption2">
                        {displayDate(row.outcomeDate) || "-"}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("status") && (
                <TableCell>
                    <Typography variant="caption2">
                        {constantMapper(row.outcomeStatus)}
                    </Typography>
                </TableCell>
            )}

            <TableCell align="right">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <ActionMenu
                        id={`${id}-outcome-row"`}
                        autoHide
                        options={[
                            {
                                label: isOutcomeFinancialConditions
                                    ? "Financial conditions details"
                                    : "Outcome details",
                                action: () =>
                                    !!callbacks?.onShowDetailsClick &&
                                    callbacks?.onShowDetailsClick(
                                        row.outcomeId,
                                    ),
                                disabled:
                                    !permissions?.hasPermissionToEditOutcome,
                            },
                            {
                                label: isOutcomeFinancialConditions
                                    ? "Cancel financial condition"
                                    : "Cancel outcome",
                                action: () =>
                                    !!callbacks?.cancelOutcome &&
                                    callbacks?.cancelOutcome(
                                        row.outcomeId,
                                        row.salessystemOrderId,
                                    ),
                                disabled:
                                    !permissions?.hasPermissionToEditOutcome ||
                                    (row.outcomeStatus !== "OPEN" &&
                                        row.outcomeStatus !== "CLOSED") ||
                                    (isOutcomeFinancialConditions &&
                                        row.financialTriggerType ===
                                            "rebateAtApheresis"),
                            },
                        ]}
                        rowIndex={rowIndex}
                    />
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default OutcomesOverviewRow;
