// Icons
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ConstructionIcon from "@mui/icons-material/Construction";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import GridOn from "@mui/icons-material/GridOn";
import MenuIcon from "@mui/icons-material/Menu";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import GroupsIcon from "@mui/icons-material/Groups";
import AssignmentIcon from "@mui/icons-material/Assignment";
import LockIcon from "@mui/icons-material/Lock";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import AutoModeIcon from "@mui/icons-material/AutoMode";

// Lib
import { StaticImage } from "gatsby-plugin-image";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import classnames from "classnames";
import { navigate } from "gatsby";
import React, { useCallback, useMemo, useState } from "react";
import { useResetRecoilState, useRecoilValue } from "recoil";

// Hooks
import { useLandingPage } from "@hooks";

// Recoil
import { uploadClaimProcessMapper } from "@selectors";

import { teamState } from "@atoms";

// Types
import { Location, Team } from "@types";

// Styles
import * as style from "./style.module.scss";

// Utils
import { truncateString } from "@utils";

// Constants
import { ROWRENDERERCONST } from "@constants";

interface User {
    info: { email: string; firstName: string; lastName: string };
    role: string;
}
interface Props {
    user: User;
    location: Location;
    id: string;
}

/**
 * Dashboard path map
 */

const DASHBOARD_PATH = [
    "/dashboard",
    "/create-contract",
    "/create-outcome",
    "/edit-partner",
    "/create-partner",
    "/order/view-order/",
    "/finances/credit-note-details/",
    "/finances/invoice-release-details/",
    "/contract/upload-file/",
    "/order/add-outcome/",
    "/contract/view-contract/",
    "/financial-conditions/financial-conditions-details/",
    "/outcomes/outcome-details/",
    "/finances/bpf-details/",
    "/contract/duplicate-contract/",
    "/contract/edit-contract/",
    "/regions/",
    "/external-codes/",
    "/claims/",
    "/price-correction-claims/",
];

/**
 * Maintenance path map
 */
const MAINTENANCE_PATH = [
    "/maintenance/product",
    "/maintenance/partner",
    "/maintenance/country",
    "/maintenance/teams",
    "/maintenance/roles",
    "/contract-team",
    "/maintenance/interface",
    "/maintenance/billing-plan",
    "/maintenance/financial-conditions",
    "/maintenance/milestone",
    "/maintenance/context",
    "/maintenance/model",
    "/maintenance/category",
    "/maintenance/users/",
];

const Sidebar: React.FC<Props> = ({ user, location, id }) => {
    const team = useRecoilValue<Team | undefined>(teamState);

    /**
     * Permissions
     */
    const {
        isUserWithRights,
        areContractsVisible,
        areOrdersVisible,
        areOutcomesVisible,
        areFinancesVisible,
        hasPermissionToEditDataTables,
        hasPermissionToEditMaintenance,
        hasPermissionToEditClaim,
        areTasksVisible,
    } = useLandingPage();

    /**
     * States
     */
    // Reset process flow
    const resetUploadClaimProcessMapper = useResetRecoilState(
        uploadClaimProcessMapper,
    );

    /**
     * Set sidebar state in localStorage
     */
    const isOpen = useMemo(() => {
        if (typeof window !== "undefined") {
            const state = localStorage.getItem("SIDEBAR_OPEN");

            if (!state || state === "false") {
                localStorage.setItem("SIDEBAR_OPEN", "false");
                return false;
            }

            return true;
        }
    }, []);
    const [open, setOpen] = useState(isOpen);

    /***
     * find active tab
     */
    const isActive = useCallback(
        (path: string) => {
            if (!location || !path) return;
            return location.pathname.includes(path);
        },
        [location],
    );

    /**
     * Reset creation flow
     */
    const handleNavigation = (path: string) => {
        location?.pathname.includes("process") &&
            resetUploadClaimProcessMapper();
        // only clear custom items
        for (const key in sessionStorage) {
            if (/^FILTER_|^SEARCH_VALUE/.test(key)) {
                sessionStorage.removeItem(key);
            }
        }

        navigate(path);
    };

    /**
     * Find dashboard
     */
    const isDashboard = useMemo(() => {
        if (!location?.pathname) return;
        const findPath = DASHBOARD_PATH.find(item =>
            location.pathname?.includes(item),
        );

        return location?.pathname === "/" || !!findPath;
    }, [location?.pathname]);

    /**
     * Find maintenance
     */
    const isMaintenance = useMemo(() => {
        if (!location?.pathname) return;
        const findPath = MAINTENANCE_PATH.find(item =>
            location.pathname?.includes(item),
        );

        return !!findPath;
    }, [location?.pathname]);

    /**
     * Render
     */
    return (
        <div className={style.wrapper}>
            <Drawer
                id={`${id}-drawer`}
                variant="persistent"
                open={true}
                className={classnames(style.drawer, {
                    [style.folded]: !open,
                })}
                classes={{
                    paper: classnames(style.paper, {
                        [style.folded]: !open,
                    }),
                }}
            >
                <Button
                    id={`${id}-logo`}
                    className={style.logo}
                    variant="text"
                    color="primary"
                    onClick={() => handleNavigation("/")}
                    size="small"
                >
                    <StaticImage
                        src={"../../../images/scp-logo.svg"}
                        alt="Smart contracting platform"
                        objectFit="contain"
                        placeholder="blurred"
                    />

                    {/*   <img src={scpLogo} alt="Smart contracting platform" /> */}
                </Button>

                <div className={style.menu}>
                    <List className={style.navs}>
                        <ListItem
                            disablePadding
                            sx={{ display: "block" }}
                            id={`${id}-list-item-opener`}
                        >
                            <ListItemButton
                                id={`${id}-slider-opener-btn`}
                                onClick={() => {
                                    setOpen(!open);
                                    localStorage.setItem(
                                        "SIDEBAR_OPEN",
                                        `${!open}`,
                                    );
                                }}
                            >
                                {open ? (
                                    <ListItemIcon>
                                        <ChevronLeftIcon color="primary" />{" "}
                                    </ListItemIcon>
                                ) : (
                                    <ListItemIcon>
                                        <MenuIcon color="primary" />
                                    </ListItemIcon>
                                )}
                            </ListItemButton>
                        </ListItem>

                        <ListItem
                            id={`${id}-dashboard`}
                            disablePadding
                            sx={{ display: "block" }}
                            className={classnames({
                                [style.active]: isDashboard,
                            })}
                        >
                            <ListItemButton
                                id={`${id}-dashboard-btn`}
                                disabled={
                                    !team ||
                                    (!areContractsVisible &&
                                        !areOrdersVisible &&
                                        !areOutcomesVisible &&
                                        !areFinancesVisible &&
                                        !hasPermissionToEditDataTables)
                                }
                                onClick={() => handleNavigation("/")}
                            >
                                <ListItemIcon sx={{ position: "relative" }}>
                                    {(!team ||
                                        (!areContractsVisible &&
                                            !areOrdersVisible &&
                                            !areOutcomesVisible &&
                                            !areFinancesVisible &&
                                            !hasPermissionToEditDataTables)) && (
                                        <LockIcon
                                            sx={{
                                                fontSize: "0.9rem",
                                                position: "absolute",
                                                left: "-14px",
                                                top: "5px",
                                            }}
                                        />
                                    )}
                                    <GridOn color="primary" />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Dashboard
                                    </Typography>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem
                            id={`${id}-maintenance`}
                            disablePadding
                            sx={{ display: "block" }}
                            className={classnames({
                                [style.active]: isMaintenance,
                            })}
                        >
                            <ListItemButton
                                id={`${id}-maintenance-btn`}
                                disabled={!hasPermissionToEditMaintenance}
                                onClick={() =>
                                    handleNavigation("/maintenance/product/")
                                }
                            >
                                <ListItemIcon sx={{ position: "relative" }}>
                                    {!hasPermissionToEditMaintenance && (
                                        <LockIcon
                                            sx={{
                                                fontSize: "0.9rem",
                                                position: "absolute",
                                                left: "-14px",
                                                top: "5px",
                                            }}
                                        />
                                    )}
                                    <ConstructionIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Maintenance
                                    </Typography>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem
                            id={`${id}-tasks`}
                            disablePadding
                            sx={{ display: "block" }}
                            className={classnames({
                                [style.active]:
                                    isActive("/tasks/ongoing-tasks/") ||
                                    isActive("/tasks/completed-tasks/") ||
                                    isActive("/tasks/task-details/"),
                            })}
                        >
                            <ListItemButton
                                id={`${id}-tasks-btn`}
                                onClick={() =>
                                    handleNavigation("/tasks/ongoing-tasks/")
                                }
                                disabled={!team || !areTasksVisible}
                            >
                                <ListItemIcon sx={{ position: "relative" }}>
                                    {(!team || !areTasksVisible) && (
                                        <LockIcon
                                            sx={{
                                                fontSize: "0.9rem",
                                                position: "absolute",
                                                left: "-14px",
                                                top: "5px",
                                            }}
                                        />
                                    )}
                                    <AssignmentIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Tasks
                                    </Typography>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem
                            id={`${id}-reports`}
                            disablePadding
                            sx={{ display: "block" }}
                            className={classnames({
                                [style.active]: isActive("/reports"),
                            })}
                        >
                            <ListItemButton
                                id={`${id}-reports-btn`}
                                onClick={() => handleNavigation("/reports/")}
                                disabled={!team || !isUserWithRights}
                            >
                                <ListItemIcon sx={{ position: "relative" }}>
                                    {(!team || !isUserWithRights) && (
                                        <LockIcon
                                            sx={{
                                                fontSize: "0.9rem",
                                                position: "absolute",
                                                left: "-14px",
                                                top: "5px",
                                            }}
                                        />
                                    )}
                                    <AssignmentReturnedIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Reports
                                    </Typography>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem
                            id={`${id}-process`}
                            disablePadding
                            sx={{ display: "block" }}
                            className={classnames({
                                [style.active]:
                                    isActive("/process/process-details") ||
                                    isActive("/process/select-contract"),
                            })}
                        >
                            <ListItemButton
                                id={`${id}-process-btn`}
                                onClick={() =>
                                    handleNavigation(
                                        "/process/process-details/",
                                    )
                                }
                                disabled={!team || !hasPermissionToEditClaim}
                            >
                                <ListItemIcon sx={{ position: "relative" }}>
                                    {(!team || !hasPermissionToEditClaim) && (
                                        <LockIcon
                                            sx={{
                                                fontSize: "0.9rem",
                                                position: "absolute",
                                                left: "-14px",
                                                top: "5px",
                                            }}
                                        />
                                    )}
                                    <AutoModeIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Process
                                    </Typography>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                    </List>
                    {!!user && (
                        <List className={style.navs}>
                            <ListItem
                                id={`${id}-user-info`}
                                disablePadding
                                sx={{ display: "block" }}
                                className={classnames({
                                    [style.active]: isActive("profile"),
                                })}
                            >
                                <ListItemButton
                                    id={`${id}-user-info-btn`}
                                    onClick={() =>
                                        handleNavigation(`/account/`)
                                    }
                                    disabled={!team}
                                >
                                    <ListItemIcon sx={{ position: "relative" }}>
                                        {!team && (
                                            <LockIcon
                                                sx={{
                                                    fontSize: "0.9rem",
                                                    position: "absolute",
                                                    left: "-14px",
                                                    top: "5px",
                                                }}
                                            />
                                        )}
                                        <PersonOutlineIcon color="primary" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant="body2">
                                            {user?.info?.firstName &&
                                            user?.info?.lastName
                                                ? `${user?.info?.firstName} ${user?.info?.lastName}`
                                                : user?.info?.email}
                                        </Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>

                            <ListItem
                                id={`${id}-user-info`}
                                disablePadding
                                sx={{ display: "block" }}
                                className={classnames({
                                    [style.active]: isActive("profile"),
                                })}
                            >
                                <Tooltip
                                    arrow
                                    title={
                                        team
                                            ? team?.teamName
                                            : "No team Selected"
                                    }
                                    id={`${id}-selected-team-tooltip`}
                                    placement="right"
                                >
                                    <ListItemButton
                                        id={`${id}-select-team-btn`}
                                        onClick={() =>
                                            handleNavigation("/select-team")
                                        }
                                    >
                                        <ListItemIcon>
                                            <GroupsIcon color="primary" />
                                        </ListItemIcon>

                                        <ListItemText>
                                            <Typography variant="body2">
                                                {team
                                                    ? truncateString(
                                                          team?.teamName,
                                                          15,
                                                          undefined,
                                                          20,
                                                      )
                                                    : "No team Selected"}
                                            </Typography>
                                        </ListItemText>
                                    </ListItemButton>
                                </Tooltip>
                            </ListItem>

                            <ListItem
                                disablePadding
                                sx={{ display: "block" }}
                                id={`${id}-logout`}
                            >
                                <ListItemButton
                                    id={`${id}-logout-btn`}
                                    onClick={() => {
                                        // clear saved viewing options in local storage
                                        Object.keys(ROWRENDERERCONST).forEach(
                                            (key: string) => {
                                                localStorage.removeItem(key);
                                            },
                                        );

                                        navigate("/logout");
                                    }}
                                >
                                    <ListItemIcon>
                                        <ExitToAppIcon color="primary" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant="body2">
                                            Logout
                                        </Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    )}
                </div>
            </Drawer>
        </div>
    );
};

export default React.memo(Sidebar);
