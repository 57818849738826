import * as yup from "yup";

const REGION_SCHEMA: yup.ObjectShape = {
    regionName: yup.string(),
    regionCode: yup.string(),
    regionId: yup.string(),
};

/**
 * Create partner schema
 */
export const createPartnerSchema = yup.object().shape({
    //  accountId: yup.string().required(),
    accountName: yup.string().required(),
    homeCountry: yup.object().shape({
        countryIsoCode: yup.string().required(),
        country: yup.string().required(),
    }),
    countryIsoCode: yup.object().shape({
        countryIsoCode: yup.string().required(),
        country: yup.string().required(),
    }),
    sapAccountCode: yup.string().nullable(),
    knowyoursupplierid: yup.string().when("sapAccountCode", value => {
        if (value && value[0]) {
            return yup.string().nullable();
        } else {
            return yup.string().required();
        }
    }),
    accountType: yup.object().shape({
        accountTypeId: yup.string().required(),
        accountTypeName: yup.string().required(),
    }),
    accountCity: yup.string().required(),
    accountAddress: yup.string().required(),
    accountPostalCode: yup.string().required(),
    accountStatus: yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
    }),
    bankType: yup.string().nullable(),
    codsId: yup.string().nullable(),
    trustAccountCode: yup
        .object()
        .shape({
            accountId: yup.string().nullable(),
            accountName: yup.string().nullable(),
        })
        .nullable(),
    cmuRegionCode: yup.object().shape(REGION_SCHEMA).nullable(),
    regionCode: yup.object().shape(REGION_SCHEMA).nullable(),
    regionCode1: yup.object().shape(REGION_SCHEMA).nullable(),
    regionCode2: yup.object().shape(REGION_SCHEMA).nullable(),
});
