import { useAxios } from "./useAxios";

// Type
import type { Api } from "@types";

// Utils
import { isSuccessfulCall, startDownload, convertBase64 } from "@utils";

const BASE_URI = `/v0`;

/**
 * Upload a claim file
 */

type ClaimType = {
    file: any;
    periodFrom: string;
    periodTo: string;
    contractPartnerId: string;
    contractId: string;
    claimCustomerReference?: string | undefined;
    claimCustomerInfo?: string | undefined;
    claimDocumentDate?: string | undefined;
    claimDueDate?: string | undefined;
    override?: boolean | undefined;
};

export const useUploadClaim = () => {
    const {
        response: uploadingResponse,
        loading: uploading,
        fetch: uploadFile,
        error: uploadError,
    }: Api = useAxios(
        {
            method: "POST",
            url: `${BASE_URI}/claim`,
        },
        { errorHandler: `Failed to upload the claim file` },
    );

    const {
        response: validationResponse,
        loading: validating,
        fetch: validateClaimFile,
        error: validationError,
        forceReset,
    }: Api = useAxios(
        {
            method: "POST",
            url: `${BASE_URI}/claim/validate`,
        },
        {
            errorHandler: `Failed to validate the claim file`,
            noSnackbarStatuses: [409],
        },
    );

    return {
        loading: { uploading, validating },
        error: { uploadError, validationError },
        response: { validationResponse, uploadingResponse },
        forceReset,
        validate: async ({
            file,
            periodFrom,
            periodTo,
            contractPartnerId,
            contractId,
            claimCustomerInfo,
            claimCustomerReference,
            claimDocumentDate,
            claimDueDate,
        }) => {
            const base64File = await convertBase64(file);
            const res = await validateClaimFile({
                data: {
                    fileData: base64File,
                    fileName: file?.name,
                    periodFrom,
                    periodTo,
                    contractPartnerId,
                    contractId,
                    claimCustomerInfo,
                    claimCustomerReference,
                    claimDocumentDate,
                    claimDueDate,
                },
                url: `${BASE_URI}/claim/validate`,
            });
            return res;
        },
        upload: async ({
            file,
            periodFrom,
            periodTo,
            contractPartnerId,
            contractId,
            claimCustomerInfo,
            claimCustomerReference,
            claimDocumentDate,
            claimDueDate,
            override,
        }: ClaimType) => {
            const base64File = await convertBase64(file);

            const res = await uploadFile({
                data: {
                    fileData: base64File,
                    fileName: file?.name,
                    periodFrom,
                    periodTo,
                    contractPartnerId,
                    contractId,
                    claimCustomerInfo,
                    claimCustomerReference,
                    claimDocumentDate,
                    claimDueDate,
                    override: override || undefined,
                },
                url: `${BASE_URI}/claim`,
            });
            return res;
        },
    };
};

/**
 * Fetch claim list
 */
export const useGetClaim = (priceCorrection = false) => {
    const mappedUrlWithQuery = `${BASE_URI}/claims?priceCorrection=${priceCorrection}`;

    const {
        response: list,
        loading: fetching,
        fetch: loadList,
        error: listError,
    }: Api = useAxios(
        {
            method: "GET",
            url: mappedUrlWithQuery,
        },
        {
            errorHandler: `Failed to fetch ${
                priceCorrection ? "price correction claims" : "claims"
            } list`,
        },
    );

    const {
        loading: canceling,
        error: cancelError,
        fetch: cancel,
    }: Api = useAxios(
        {
            method: "POST",
            url: `${BASE_URI}/claim`,
        },
        { errorHandler: `Failed to download file` },
    );

    const {
        loading: deleting,
        error: deleteError,
        fetch: deleteClaim,
    }: Api = useAxios({
        method: "DELETE",
    });

    const {
        loading: approving,
        error: approveError,
        fetch: approve,
    }: Api = useAxios({
        method: "POST",
    });

    const {
        loading: editing,
        error: editError,
        fetch: edit,
    }: Api = useAxios({
        method: "POST",
    });

    return {
        list: list,
        loading: { fetching, canceling, deleting, approving, editing },
        error: { listError, cancelError, deleteError, approveError, editError },
        reload: () => loadList(),
        search: (searchParams: string) =>
            loadList({
                url: `${mappedUrlWithQuery}${searchParams}`,
            }),
        cancel: (claimHeaderId: string, filename: string, reasonCancellation) =>
            cancel(
                {
                    url: `${BASE_URI}/claim/${claimHeaderId}/cancel`,
                    data: { reasonCancellation },
                },
                {
                    errorHandler: `Failed to request claim cancellation`,
                    successHandler: `Claim cancellation for ${filename} was successfully requested`,
                },
            ),
        delete: (claimHeaderId: string, filename: string) =>
            deleteClaim(
                {
                    url: `${BASE_URI}/claim/${claimHeaderId}`,
                },
                {
                    errorHandler: `Failed to delete ${filename}`,
                    successHandler: `Claim (${filename}) was successfully deleted`,
                },
            ),
        approve: (claimHeaderId: string, filename: string) =>
            approve(
                {
                    url: `${BASE_URI}/claim/${claimHeaderId}/approve`,
                },
                {
                    errorHandler: `Failed to send ${filename} for approval`,
                    successHandler: `Claim (${filename}) was successfully sent for approval`,
                },
            ),
        edit: (claimHeaderId: string, filename: string, data) =>
            edit(
                {
                    url: `${BASE_URI}/claim/${claimHeaderId}/edit`,
                    data,
                },
                {
                    errorHandler: `Failed to edit ${filename}`,
                    successHandler: `Claim (${filename}) was successfully updated`,
                },
            ),
    };
};

/**
 * Fetch claim by Id
 */
export const useGetClaimById = () => {
    const {
        response: list,
        loading,
        error,
        fetch,
    }: Api = useAxios(
        {
            method: "GET",
            url: `${BASE_URI}/claim`,
        },
        { errorHandler: `Failed to fetch claim details` },
    );

    const {
        loading: downloadingClaimDetails,
        error: pdfLocationError,
        fetch: fetchClaimDetailsPdfLocation,
    }: Api = useAxios({
        method: "GET",
    });

    const {
        loading: downloadingClaimInvoice,
        error: invoicePdfLocationError,
        fetch: fetchClaimInvoicePdfLocation,
    }: Api = useAxios({
        method: "GET",
    });

    const {
        loading: downloadingClaimAttachment,
        error: claimAttachmentLocationError,
        fetch: fetchClaimAttachmentLocation,
    }: Api = useAxios({
        method: "GET",
    });

    const {
        loading: downloading,
        error: downloadError,
        fetch: download,
    }: Api = useAxios(
        {
            method: "GET",
            url: `${BASE_URI}/claim`,
        },
        { errorHandler: `Failed to download file` },
    );

    const {
        loading: editingNotes,
        error: editNotesError,
        fetch: editNotes,
    }: Api = useAxios(
        {
            method: "PUT",
        },
        { errorHandler: `Failed to edit claim notes` },
    );

    const {
        loading: uploadingAttachments,
        error: uploadAttachmentsError,
        fetch: uploadAttachments,
    }: Api = useAxios({
        method: "PUT",
    });

    return {
        list,
        loading: {
            loading,
            downloading,
            downloadingClaimDetails,
            downloadingClaimInvoice,
            editingNotes,
            uploadingAttachments,
            downloadingClaimAttachment,
        },
        error: {
            error,
            downloadError,
            pdfLocationError,
            invoicePdfLocationError,
            editNotesError,
            uploadAttachmentsError,
            claimAttachmentLocationError,
        },
        load: (claimId: string) =>
            fetch({ url: `${BASE_URI}/claim/${claimId}` }),
        download: (
            claimId: string,
            type:
                | "BPF"
                | "CLAIM"
                | "CLAIM_CUSTOMER"
                | "CLAIM_VALIDATION"
                | "CLAIM_PRICE_CORRECTION",
            name: string,
        ) =>
            download(
                {
                    url: `${BASE_URI}/claim/${claimId}/file?type=${type}`,
                },
                {
                    errorHandler: `Failed to download ${name}`,
                },
            ).then(res => {
                if (isSuccessfulCall(res?.status) && !!res?.data?.location) {
                    startDownload(res.data.location);
                    return res;
                }
                return res;
            }),
        downloadClaimDetails: (claimId: string) =>
            fetchClaimDetailsPdfLocation(
                {
                    url: `${BASE_URI}/pdf/claim/${claimId}`,
                },
                {
                    errorHandler: `Failed to fetch ClaimDetails.pdf`,
                },
            ).then(res => {
                if (isSuccessfulCall(res?.status) && !!res?.data?.location) {
                    startDownload(res.data.location);
                    return res;
                }
                return res;
            }),
        downloadClaimInvoice: (claimId: string, fileName: string) =>
            fetchClaimInvoicePdfLocation(
                {
                    url: `${BASE_URI}/pdf/claim/${claimId}/invoice`,
                },
                {
                    errorHandler: `Failed to fetch ${fileName}`,
                },
            ).then(res => {
                if (isSuccessfulCall(res?.status) && !!res?.data?.location) {
                    startDownload(res.data.location);
                    return res;
                }
                return res;
            }),
        downloadClaimAttachment: (
            claimAttachmentId: string,
            fileName: string,
        ) =>
            fetchClaimAttachmentLocation(
                {
                    url: `${BASE_URI}/claim/attachment/${claimAttachmentId}/download`,
                },
                {
                    errorHandler: `Failed to fetch ${fileName}`,
                },
            ).then(res => {
                if (isSuccessfulCall(res?.status) && !!res?.data?.location) {
                    startDownload(res.data.location);
                    return res;
                }
                return res;
            }),
        editNotes: async (claimId, notes) =>
            editNotes(
                {
                    url: `${BASE_URI}/claim/${claimId}/note`,
                    data: { notes },
                },
                {
                    successHandler: `Notes were successfully submitted`,
                },
            ),

        uploadAttachments: async (claimHeaderId: string, files: Array<any>) => {
            const based64Files = await Promise.all(
                files.map(async file => {
                    if (file?.file) {
                        return {
                            fileData: await convertBase64(file?.file),
                            fileName: file?.file?.name,
                            fileDescription: file?.fileDescription,
                        };
                    }
                    return file;
                }),
            );

            const mixedFiles = [...based64Files];

            return uploadAttachments(
                {
                    url: `${BASE_URI}/claim/${claimHeaderId}/attachment`,
                    data: { attachments: mixedFiles },
                },
                {
                    errorHandler: `Failed to upload some files`,
                    successHandler: `Files were successfully uploaded`,
                },
            );
        },
    };
};
