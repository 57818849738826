// Lib
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { Fragment, useEffect, useMemo, useState } from "react";

// Own components
import { FieldRenderer, Modal, ContractPartnersForm, Card } from "@components";

// Types
import { Partner, Account } from "@types";

// Utils
import { isArrayWithContent } from "@utils";

type PartnerProp = {
    loading: boolean;
    data: Array<Account>;
    onSearch: (query: string) => void;
};
/**
 * Props
 */
interface Props {
    hideEdit?: boolean;
    disableEdit?: boolean;
    additionalPartners: Partner[];
    callbacks?: {
        onEditClick?: (path: string) => void;
        onEditContractItem?: (values: any) => void;
    };

    id: string;
    shrinkColumns?: boolean;
    isEditContract?: boolean;
    partners?: PartnerProp;
    primaryPartnerId: string;
    isCreateContract?: boolean;
}

/**
 * AdditionalPartnersSummary
 */
const AdditionalPartnersSummary: React.FC<Props> = ({
    additionalPartners,
    id,
    hideEdit = false,
    callbacks,
    shrinkColumns,
    isEditContract,
    primaryPartnerId,
    partners,
    disableEdit,
    isCreateContract,
}) => {
    const [additionalPartnersList, setPartners] = useState<Account[]>([]);
    const [openEditModal, setEditModal] = useState(false);

    /**
     * Disable save
     */
    const disabled = useMemo(() => {
        if (!isArrayWithContent(additionalPartnersList)) return false;

        return additionalPartnersList?.some((el: Account) => !el.accountId);
    }, [additionalPartnersList]);

    useEffect(() => {
        if (!isArrayWithContent(additionalPartners)) {
            setPartners([]);
            return;
        }

        if (!openEditModal) {
            setPartners([]);
        } else {
            const mappedParterns = additionalPartners?.map(
                ({ id, name }: any) => {
                    return {
                        accountId: id,
                        accountName: name,
                    };
                },
            );
            setPartners(mappedParterns as any);
        }
    }, [openEditModal]);

    /**
     * Map partners to resolve values overlapping among the api's
     */
    const mapPartners = () => {
        if (!callbacks?.onEditContractItem) return;

        const mappedValues = isArrayWithContent(additionalPartnersList)
            ? additionalPartnersList.map(el => {
                  return { id: el.accountId, name: el.accountName };
              })
            : [];

        callbacks?.onEditContractItem({ additionalPartners: mappedValues });
    };

    /**
     * Render
     */
    return (
        <Fragment>
            <Modal
                open={openEditModal}
                id={`edit-categories`}
                title={"Additional partners"}
                mediumView
                onClose={() => {
                    setEditModal(false);
                }}
                primaryButton={{
                    action: () => {
                        mapPartners();
                        setEditModal(false);
                    },
                    text: "Save",
                    disabled: disabled,
                }}
                secondaryButton={{
                    action: () => {
                        setPartners([]);
                        setEditModal(false);
                    },
                    text: "Cancel",
                }}
            >
                <Box mb={2}>
                    <ContractPartnersForm
                        id={`${id}-edit-contract-partner-form`}
                        partners={partners as PartnerProp}
                        values={additionalPartnersList}
                        onChange={setPartners}
                        errors={{}}
                        touched={{}}
                        onBlur={undefined}
                        primaryPartnerId={primaryPartnerId}
                    />
                </Box>
            </Modal>
            <Card
                id={`${id}-additional-partners`}
                title="Additional partners"
                action={{
                    icon: <EditIcon fontSize="small" />,
                    hidden: hideEdit || (!isEditContract && !isCreateContract),
                    disabled: disableEdit,
                    action: () =>
                        isEditContract
                            ? setEditModal(true)
                            : !!callbacks?.onEditClick &&
                              callbacks.onEditClick("additional-partners"),
                }}
            >
                {!!additionalPartners &&
                isArrayWithContent(additionalPartners) ? (
                    <Grid
                        item
                        xs={12}
                        container
                        rowSpacing={3}
                        columnSpacing={4}
                    >
                        {additionalPartners.map((partner, index) => (
                            <Grid
                                item
                                xs={12}
                                key={index}
                                md={shrinkColumns ? 3 : 6}
                            >
                                <FieldRenderer
                                    id={`${id}-${index}`}
                                    label=""
                                    value={partner?.name}
                                />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Box display="flex" justifyContent="center">
                        <Typography variant="caption1">
                            No additional partners found!
                        </Typography>
                    </Box>
                )}
            </Card>
        </Fragment>
    );
};

export default React.memo(AdditionalPartnersSummary);
