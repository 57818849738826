//lib
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useFormik, FormikProps } from "formik";
import React, { useEffect, useImperativeHandle } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

// Own components
import { ProductFinancialConditionsForm, FormFooter } from "@components";

// Custom hooks
import {
    useUpdateStep,
    useFormValidation,
    useGetFinancialConditions,
} from "@hooks";

// Atoms
import {
    financialConditionsState,
    createPerformanceBasedContractStepsState,
    generalInformationState,
    productsDetailsState,
    selectModelState,
} from "@atoms";

// Schemas
import { financialConditionsSchema } from "@schemas";

// Types
import {
    FlowStep,
    Location,
    ProductFinancialConditions,
    MappedProductsForClient,
    Brand,
} from "@types";
import { isObjectWithContent } from "@utils";

/**
 * Props type
 */
interface Props {
    location?: Location;
    currentStep?: FlowStep;
    id?: string;
}

/**
 * Contract Financial Conditions
 */
const ContractFinancialConditions = React.forwardRef(
    ({ location, currentStep, id }: Props, ref) => {
        /**
         * API
         */
        const {
            getFinancialConditions,
            loading,
            response: financialConditionsList,
        } = useGetFinancialConditions();

        /**
         * Recoil
         */
        const contractProducts: MappedProductsForClient =
            useRecoilValue(productsDetailsState);
        const { brand }: { brand: Brand & { name?: string } } = useRecoilValue(
            generalInformationState,
        );

        const [financialConditions, updateFinancialConditions] =
            useRecoilState<any>(financialConditionsState);

        // Model state
        const { currency } = useRecoilValue(selectModelState);

        /**
         *  Update step hook
         */
        const updateStepValidation = useUpdateStep(
            location,
            createPerformanceBasedContractStepsState,
        );

        /**
         * Formik state
         */
        const formik: FormikProps<ProductFinancialConditions> =
            useFormik<ProductFinancialConditions>({
                initialValues: {
                    ...financialConditions,
                },
                validationSchema: financialConditionsSchema,
                enableReinitialize: true,
                validateOnMount: true,
                onSubmit: () => undefined,
            });

        /**
         * Form validation
         */
        const { isValidForm, hasErrors } = useFormValidation(
            formik.errors,
            formik.touched,
            currentStep,
        );

        const canSaveState =
            isValidForm || !isObjectWithContent(formik.values?.conditions); // Exceptional validation: check if there are no conditions (default is valid and prepared is true)
        /**
         * Check if the current step is valid => set isPrepared to true,
         * otherwise set it false
         */
        useEffect(() => {
            updateStepValidation(canSaveState);
        }, [canSaveState]);

        /**
         * Save state and go to the next page (controlled by the layout)
         */
        useImperativeHandle(ref, () => ({
            updateState() {
                updateFinancialConditions(formik.values);
            },
        }));

        return (
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height={1}
            >
                <div>
                    <Box mb={7}>
                        <Typography variant="h2">
                            {"Financial conditions"}
                        </Typography>
                    </Box>
                    <ProductFinancialConditionsForm
                        id={`${id}-financial-conditions-form`}
                        productName={
                            contractProducts?.products[0]?.product?.productName
                        }
                        brandName={brand?.brandName || brand?.name || ""}
                        sapSkuNo={
                            contractProducts?.products[0]?.product?.sapSkuNo
                        }
                        currency={currency}
                        price={
                            contractProducts?.products[0]?.priceCondition?.price
                        }
                        financialConditions={formik.values?.conditions}
                        errors={formik.errors}
                        touched={formik.touched}
                        setTouched={formik.setTouched}
                        onBlur={formik.handleBlur}
                        financialConditionsList={{
                            load: getFinancialConditions,
                            data: financialConditionsList?.data?.records,
                            loading,
                        }}
                        onChange={conditions =>
                            formik.setFieldValue("conditions", conditions)
                        }
                    />
                </div>

                <FormFooter
                    error={hasErrors}
                    textAlign="right"
                    id={`${id}-financial-conditions-form-footer`}
                />
            </Box>
        );
    },
);

export default ContractFinancialConditions;
